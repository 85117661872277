<script>
    export default {};
</script>

<template>
    <div class="NotFound">
        <img src="@/assets/404.png" class="img" alt="" />
    </div>
</template>

<style lang='less' scrop>
.NotFound {
    width: 1200px;
    margin: auto;
    .img {
        widows: 100%;
        margin: 0 auto;
    }
}
</style>
